import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getProducts,
  getProductDetail,
  postProduct,
  putProduct,
  putProductStatus,
  getProductGroups,
  postProductGroup,
} from "./productAPI";
import _ from "lodash";
import { getTop5Products } from "features/packages/packageAPI";

const initialState = {
  list: undefined,
  topList: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  group: [],
};

export const getProductsAsync = createAsyncThunk(
  "products",
  async (params, thunkAPI) => {
    try {
      const response = await getProducts(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getProductDetailAsync = createAsyncThunk(
  "productDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getProductDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postProductAsync = createAsyncThunk(
  "postProduct",
  async (data, thunkAPI) => {
    try {
      const response = await postProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putProductAsync = createAsyncThunk(
  "putProduct",
  async (data, thunkAPI) => {
    try {
      const response = await putProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putProductStatusAsync = createAsyncThunk(
  "putProductStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getProductGroupsAsync = createAsyncThunk(
  "products-group",
  async (params, thunkAPI) => {
    try {
      const response = await getProductGroups(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postProductGroupAsync = createAsyncThunk(
  "postProduct-group",
  async (data, thunkAPI) => {
    try {
      const response = await postProductGroup(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getTop5ProductsAsync = createAsyncThunk(
  "top5Products",
  async (params, thunkAPI) => {
    try {
      const response = await getTop5Products(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearProductDetail: (state) => {
      state.detail = undefined;
    },
    clearProducts: (state) => {
      state.list = undefined;
    },
    updateProductList: (state, action) => {
      state.list = action.payload;
    },
    updateProductDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Lấy dữ liệu thất bại!");
        state.error = {
          payload: action.payload,
          title: "getProducts",
        };
      })
      .addCase(getProductDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getProductDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Lấy dữ liệu thất bại!");
        state.error = {
          payload: action.payload,
          title: "getProductDetail",
        };
      })
      .addCase(postProductAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postProductAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putProductAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        message.success("Cập nhật sản phẩm thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Cập nhật sản phẩm thất bại!");
        state.error = {
          payload: action.payload,
          title: "putProduct",
        };
      })
      .addCase(putProductStatusAsync.pending, (state) => {
        state.status = "update_status_loading";
      })
      .addCase(putProductStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductStatusAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Cập nhật trạng thái thất bại!");
        state.error = {
          payload: action.payload,
          title: "putProductStatus",
        };
      })
      .addCase(getProductGroupsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductGroupsAsync.fulfilled, (state, action) => {
        state.group = action.payload.result.data;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductGroupsAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Lấy dữ liệu thất bại!");
        state.error = {
          payload: action.payload,
          title: "getProducts-group",
        };
      })
      .addCase(postProductGroupAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postProductGroupAsync.fulfilled, (state, action) => {
        let clone = _.cloneDeep(state?.group);
        state.group.unshift(action?.payload?.result);
        state.status = "done";
        state.error = undefined;
      })
      .addCase(getTop5ProductsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTop5ProductsAsync.fulfilled, (state, action) => {
        state.topList = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getTop5ProductsAsync.rejected, (state, action) => {
        state.status = "idle";
        message.error("Lấy dữ liệu thất bại!");
        state.error = {
          payload: action.payload,
          title: "getProducts",
        };
      });
  },
});

export const selectProducts = (state) => state.products.list;
export const selectTop5Products = (state) => state.products.topList;
export const selectProductDetail = (state) => state.products.detail;
export const productsStatus = (state) => state.products.status;
export const productsError = (state) => state.products.error;
export const selectProductGroups = (state) => state.products.group;

export const {
  clearProductDetail,
  clearProducts,
  setIdleStatus,
  updateProductList,
  updateProductDetail,
} = productSlice.actions;

export default productSlice.reducer;
