import { createAsyncThunk, createAsytopicThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getEventDetail, getEvents, postEvent, putStatusEvent } from './eventAPI';


const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getEventsAsync = createAsyncThunk(
    'events',
    async (params, thunkAPI) => {
        try {
            const response = await getEvents(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getEventDetailAsync = createAsyncThunk(
    'eventDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getEventDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postEventAsync = createAsyncThunk(
    'postEvent',
    async (data, thunkAPI) => {
        try {
            const response = await postEvent(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const putEventStatusAsync = createAsyncThunk(
    'putEventStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putStatusEvent(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);


export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearEventDetail: state => {
            state.detail = undefined;
        },
        clearEvents: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // ieventluding actions generated by createAsyeventThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getEventsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEventsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getEventsAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getEvents"
                    }
                }
            })
            .addCase(getEventDetailAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEventDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getEventDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getEventDetail"
                    }
                }
            })
            .addCase(postEventAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postEventAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postEventAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Thêm mới sự kiện thất bại")
                    state.error = {
                        payload: action.payload,
                        title: "addEvent"
                    }
                }
            })
            .addCase(putEventStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putEventStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putEventStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putEventStatus"
                    }
                }
            })
    },
});

export const selectEvents = state => state.events.list
export const selectEventDetail = state => state.events.detail
export const eventsStatus = state => state.events.status
export const eventsError = state => state.events.error

export const { clearEventDetail, clearEvents, setIdleStatus } = eventSlice.actions;

export default eventSlice.reducer;

