import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import langReducer from "../features/lang/langSlice";
import productReducer from "../features/product/productSlice";

import nccReducer from "../features/ncc/nccSlice";
import ncoReducer from "../features/nco/ncoSlice";
import ncReducer from "../features/nc/ncSlice";
import areaReducer from "../features/area/areaSlice";
import provinceReducer from "../features/province/provinceSlice";
import categoryReducer from "../features/category/categorySlice";
import packageReducer from "../features/packages/packageSlice";
import topicReducer from "../features/topic/topicSlice";
import trackandtraceReducer from "../features/trackandtrace/trackandtraceSlice";
import eventReducer from "../features/event/eventSlice";
import checkinReducer from "../features/checkin/checkinSlice";
import challengeReducer from "../features/challenge/challengeSlice";
import masterImportReducer from "../features/masterImport/masterImportSlice";
import notificationReducer from "../features/notification/notiSlice";
import simulationReducer from "../features/simulation/simulationSlice";
import creatorReducer from "../features/creator/creatorSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import activityLogReducer from "../features/activeLog/activityLogSlice";
import versionReducer from "../features/version/versionSlice";
import featureReducer from "../features/feature/featureSlice";
import stickerReducer from "../features/sticker/stickerSlice";
import newmeReducer from "../features/newme/newmeSlice";
import manageGiftReducer from "../features/manageGift/manageGiftSlice";
import weightLossReducer from "../features/wlm/wlmSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    lang: langReducer,
    products: productReducer,

    ncc: nccReducer,
    nco: ncoReducer,
    nc: ncReducer,
    area: areaReducer,
    province: provinceReducer,
    categories: categoryReducer,
    packages: packageReducer,
    topics: topicReducer,
    trackandtraces: trackandtraceReducer,
    events: eventReducer,
    checkin: checkinReducer,
    challenges: challengeReducer,
    masterImport: masterImportReducer,
    notification: notificationReducer,
    simulations: simulationReducer,
    creator: creatorReducer,
    dashboard: dashboardReducer,
    activityLog: activityLogReducer,
    versions: versionReducer,
    features: featureReducer,
    stickers: stickerReducer,
    newme: newmeReducer,
    manageGift: manageGiftReducer,
    weightLoss: weightLossReducer
  },
});
