import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getCategories = (params) => {
    return axiosClient.get("/categories", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getCategoryDetail = (id) => {
    return axiosClient.get("/categories/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postCategory = (data) => {
    return axiosClient.post("/categories", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putCategory = (data) => {
    return axiosClient.patch("/categories/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getCategories,
    getCategoryDetail,
    postCategory,
    putCategory,
}