import { options } from "numeral";
import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNCOs = (params) => {
  return axiosClient.get("members", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getNCODetail = (id) => {
  return axiosClient.get("members/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postNCO = (data) => {
  return axiosClient.post("members", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getNOs = (params) => {
  return axiosClient.get("nutrition-clubs", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putNCO = (data) => {
  return axiosClient.patch("members/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getType = (params) => {
  return axiosClient.get("enums/nutrition-club-customer-levers", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportNCOs = (params, options) => {
  return axiosClient.get("members/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const resetPasswordNCO = (id) => {
  return axiosClient.patch(`members/dat-lai-mat-khau?memberId=${id}`, null, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const logoutNCO = (id) => {
  return axiosClient.patch(`members/dang-xuat-tai-khoan?memberId=${id}`, null, {
    headers: {
      Authorization: "Bearer " + getToken(),
      "content-type": "application/json;charset=UTF-8",
    },
  });
};

export {
  getNCOs,
  getNCODetail,
  postNCO,
  getNOs,
  putNCO,
  getType,
  exportNCOs,
  resetPasswordNCO,
  logoutNCO,
};
