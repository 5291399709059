import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getCheckin = (params) => {
    return axiosClient.get("check-in", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getCheckinDetail = (id) => {
    return axiosClient.get("check-in/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postCheckin = (data) => {
    return axiosClient.post("check-in", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}


const putCheckin = (data) => {
    return axiosClient.put("check-in/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteCheckin = (id) => {
    return axiosClient.delete("check-in/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPackages = (params) => {
    return axiosClient.get("packages", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getSession = (params) => {
    return axiosClient.get("enums/check-in-sessions", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMember = (params) => {
    return axiosClient.get("check-in/members-not-yet-checkin", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getMemberList = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportCheckin = (params, options) => {
    return axiosClient.get("check-in/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getCheckin,
    postCheckin,
    getCheckinDetail,
    putCheckin,
    deleteCheckin,
    getPackages,
    getSession,
    getMember,
    getMemberList,
    exportCheckin
}