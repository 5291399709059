import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getWeightLoss, getWeightLossDashboard, getWeightLossDetail, getWeightLossGroup, getWeightLossGroupById, getWeightLossGroupDetail, getWeightLossMemberById } from "./wlmAPI";


const initialState = {
  listWeightLoss: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
  type: undefined,
  group: undefined,
  member: undefined,
  groupDetail: undefined,
  dashboard: undefined
};

export const getWeightLossAsync = createAsyncThunk(
  "weightLoss",
  async (params, thunkAPI) => {
    try {
      const response = await getWeightLoss(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossDashboardAsync = createAsyncThunk(
  "weightLossDashboard",
  async (params, thunkAPI) => {
    try {
      const response = await getWeightLossDashboard(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossGroupAsync = createAsyncThunk(
  "weightLossGroup",
  async (params, thunkAPI) => {
    try {
      const response = await getWeightLossGroup(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossDetailAsync = createAsyncThunk(
  "weightLossDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getWeightLossDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossGroupDetailAsync = createAsyncThunk(
  "weightLossGroupDetail",
  async (params, thunkAPI) => {
    try {
      const response = await getWeightLossGroupDetail(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossGroupByIdAsync = createAsyncThunk(
  "weightLossGroupById",
  async ({id, params}, thunkAPI) => {
    try {
      const response = await getWeightLossGroupById(id,params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getWeightLossMemberByIdAsync = createAsyncThunk(
  "weightLossMember",
  async ({id, params}, thunkAPI) => {
    try {
      const response = await getWeightLossMemberById(id,params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const weightLossSlice = createSlice({
  name: "weightLoss",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearWeightLossDetail: (state) => {
      state.detail = undefined;
    },
    clearWeightLoss: (state) => {
      state.listWeightLoss = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getWeightLossAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossAsync.fulfilled, (state, action) => {
        state.listWeightLoss = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLoss",
          };
        }
      })
      .addCase(getWeightLossDetailAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.type = action.payload.result.type
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossDetail",
          };
        }
      })
      .addCase(getWeightLossGroupByIdAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossGroupByIdAsync.fulfilled, (state, action) => {
        state.group = action.payload; 
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossGroupByIdAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossGroupById",
          };
        }
      })
      .addCase(getWeightLossMemberByIdAsync.fulfilled, (state, action) => {
        state.member = action.payload; 
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossMemberByIdAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossMemberById",
          };
        }
      })
      .addCase(getWeightLossDashboardAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossDashboardAsync.fulfilled, (state, action) => {
        state.dashboard = action.payload; 
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossDashboardAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossDashboard",
          };
        }
      })
      .addCase(getWeightLossGroupAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossGroupAsync.fulfilled, (state, action) => {
        state.group = action.payload; 
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossGroupAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossGroup",
          };
        }
      })
      .addCase(getWeightLossGroupDetailAsync.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(getWeightLossGroupDetailAsync.fulfilled, (state, action) => {
        state.groupDetail = action.payload; 
        state.type = action.payload.result?.weightLoss?.type
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWeightLossGroupDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWeightLossGroupDetail",
          };
        }
      })
  },
});

export const selectWeightLoss = (state) => state.weightLoss.listWeightLoss;
export const selectWeightLossDetail = (state) => state.weightLoss.detail;
export const selectWeightLossGroupDetail = (state) => state.weightLoss.groupDetail;
export const selectWeightLossGroup = (state) => state.weightLoss.group;
export const selectWeightLossMember = (state) => state.weightLoss.member;
export const selectWeightLossDashboard = (state) => state.weightLoss.dashboard;
export const weightLossStatus = (state) => state.weightLoss.status;
export const weightLossType = (state) => state.weightLoss.type;
export const weightLossError = (state) => state.weightLoss.error;

export const { clearWeightLossDetail, clearWeightLoss, setIdleStatus } = weightLossSlice.actions;

export default weightLossSlice.reducer;
