import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getProducts = (params) => {
    return axiosClient.get("product", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductDetail = (id) => {
    return axiosClient.get("product/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductsByUnit = (params) => {
    return axiosClient.get("product-detail", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postProduct = (data) => {
    return axiosClient.post("product", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProduct = (data) => {
    return axiosClient.patch("product/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProductImage = (id, data) => {
    return axiosClient.patch("product/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProductStatus = (id, data) => {
    return axiosClient.put("product-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportProducts = (params, options) => {
    return axiosClient.get("product/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteProductImage = (id, fileUploadId) => {
    return axiosClient.delete(`product/${id}/` + `picture/${fileUploadId}` , {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getProductGroups = (params) => {
    return axiosClient.get("product-group", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postProductGroup = (data) => {
    return axiosClient.post("product-group", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getProducts,
    getProductDetail,
    postProduct,
    putProduct,
    putProductImage,
    putProductStatus,
    getProductsByUnit,
    exportProducts,
    deleteProductImage,
    getProductGroups,
    postProductGroup
}