import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getProvinces,
    // getProvinceDetail,
    // postProvince,
} from './provinceAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getProvincesAsync = createAsyncThunk(
    'provinces',
    async (params, thunkAPI) => {
        try {
            const response = await getProvinces(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

// export const getProvinceDetailAsync = createAsyncThunk(
//     'ncDetail',
//     async (id, thunkAPI) => {
//         try {
//             const response = await getProvinceDetail(id);
//             // The value we return becomes the `fulfilled` action payload
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data)
//         }
//     }
// );

// export const postProvinceAsync = createAsyncThunk(
//     'postProvince',
//     async (data, thunkAPI) => {
//         try {
//             const response = await postProvince(data);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data)
//         }
//     }
// );


export const provinceSlice = createSlice({
    name: 'province',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearProvinceDetail: state => {
            state.detail = undefined;
        },
        clearProvinces: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getProvincesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getProvincesAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getProvincesAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getProvinces"
                    }
                }
            })
            // .addCase(getProvinceDetailAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(getProvinceDetailAsync.fulfilled, (state, action) => {
            //     state.detail = action.payload
            //     state.status = 'idle';
            //     state.error = undefined;
            // })
            // .addCase(getProvinceDetailAsync.rejected, (state, action) => {
            //     state.status = 'idle';
            //     if (action?.error?.name !== "AbortError") {
            //         message.error("Lấy dữ liệu thất bại!")
            //         state.error = {
            //             payload: action.payload,
            //             title: "getProvinceDetail"
            //         }
            //     }
            // })
            // .addCase(postProvinceAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(postProvinceAsync.fulfilled, (state, action) => {
            //     state.detail = action.payload
            //     state.status = 'done';
            //     state.error = undefined;
            // })
            // .addCase(postProvinceAsync.rejected, (state, action) => {
            //     state.status = 'idle';
            //     if(action?.error?.name !== "AbortError"){
            //         message.error("Create Province failed")
            //         state.error = {
            //             payload: action.payload,
            //             title: "addProvince"
            //         }
            //     }
            // })
    },
});

export const selectProvinces = state => state.province.list
export const selectProvinceDetail = state => state.province.detail
export const provincesStatus = state => state.province.status
export const provincesError = state => state.province.error

export const { clearProvinceDetail, clearProvinces, setIdleStatus } = provinceSlice.actions;

export default provinceSlice.reducer;

