import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import { getFeatures, putFeatures } from "./featureAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getFeaturesAsync = createAsyncThunk(
  "features",
  async (params, thunkAPI) => {
    try {
      const response = await getFeatures(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putFeaturesAsync = createAsyncThunk(
  "putFeatureStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putFeatures(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const versionSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearVersionDetail: (state) => {
      state.detail = undefined;
    },
    clearFeatures: (state) => {
      state.list = undefined;
    },
    updateVersionDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFeaturesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFeaturesAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getFeaturesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getFeatures",
          };
        }
      })
      .addCase(putFeaturesAsync.pending, (state) => {
        state.status = "update_status_loading";
      })
      .addCase(putFeaturesAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putFeaturesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Đổi trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putEventStatus",
          };
        }
      });
  },
});

export const selectFeatures = (state) => state.features.list;
export const featuresStatus = (state) => state.features.status;
export const featuresError = (state) => state.features.error;

export const {
  clearVersionDetail,
  clearFeatures,
  setIdleStatus,
  updateVersionDetail,
} = versionSlice.actions;

export default versionSlice.reducer;
