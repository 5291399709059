import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { message } from "antd";
import {
  activeSticker,
  deActiveSticker,
  getStickerDetail,
  getStickers,
  postSticker,
  putSticker,
} from "./stickerAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getStickersAsync = createAsyncThunk(
  "stickers",
  async (params, thunkAPI) => {
    try {
      const response = await getStickers(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStickerDetailAsync = createAsyncThunk(
  "eventDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getStickerDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postStickerAsync = createAsyncThunk(
  "postSticker",
  async (data, thunkAPI) => {
    try {
      const response = await postSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putStickerAsync = createAsyncThunk(
  "putSticker",
  async (data, thunkAPI) => {
    try {
      const response = await putSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const activeStickerAsync = createAsyncThunk(
  "activeSticker",
  async (data, thunkAPI) => {
    try {
      const response = await activeSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deActiveStickerAsync = createAsyncThunk(
  "de-activeSticker",
  async (data, thunkAPI) => {
    try {
      const response = await deActiveSticker(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const stickerSlice = createSlice({
  name: "sticker",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearStickerDetail: (state) => {
      state.detail = undefined;
    },
    clearStickers: (state) => {
      state.list = undefined;
    },
    updateStickerDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getStickersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStickersAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStickersAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getStickers",
          };
        }
      })
      .addCase(getStickerDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStickerDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "idle";
        state.error = false;
      })
      .addCase(getStickerDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getStickerDetail",
          };
        }
      })
      .addCase(postStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postStickerAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(postStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Create Sticker failed");
          state.error = {
            payload: action.payload,
            title: "addSticker",
          };
        }
      })
      .addCase(putStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putStickerAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại");
          state.error = {
            payload: action.payload,
            title: "addSticker",
          };
        }
      })
      .addCase(activeStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(activeStickerAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(activeStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại");
          state.error = {
            payload: action.payload,
            title: "activeVersion",
          };
        }
      })
      .addCase(deActiveStickerAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deActiveStickerAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(deActiveStickerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại");
          state.error = {
            payload: action.payload,
            title: "activeVersion",
          };
        }
      });
  },
});

export const selectStickers = (state) => state.stickers.list;
export const selectStickerDetail = (state) => state.stickers.detail;
export const stickersStatus = (state) => state.stickers.status;
export const stickersError = (state) => state.stickers.error;

export const {
  clearStickerDetail,
  clearStickers,
  setIdleStatus,
  updateStickerDetail,
} = stickerSlice.actions;

export default stickerSlice.reducer;
