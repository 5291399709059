import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getNotis = (params) => {
    return axiosClient.get("notification", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNotiDetail = (id) => {
    return axiosClient.get("notification/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postNoti = (data) => {
    return axiosClient.post("notification", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNoti = (id, data) => {
    return axiosClient.patch("notification/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putStatusNoti = (data) => {
    return axiosClient.patch(`notification/${data?.id}/status/${data?.values?.status}`, {}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportNoti = (params, options) => {
    return axiosClient.get("notification/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNotiImage = (id, data) => {
    return axiosClient.patch("notification/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteNotiImage = (id, fileUploadId) => {
    return axiosClient.delete(`notification/${id}/` + `picture/${fileUploadId}` , {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
export {
    getNotis,
    getNotiDetail,
    postNoti,
    putNoti,
    putStatusNoti,
    exportNoti,
    putNotiImage,
    deleteNotiImage
}