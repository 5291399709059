import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getWeightLoss = (params) => {
    return axiosClient.get("weight-loss", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossGroup = (params) => {
    return axiosClient.get("weight-loss/groups", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossDashboard = (params) => {
    return axiosClient.get("weight-loss/dashboard", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossDetail = (id,params) => {
    return axiosClient.get("weight-loss/"+id, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossGroupDetail = (id,params) => {
    return axiosClient.get("weight-loss/groups/"+id, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossGroupById = (id,params) => {
    return axiosClient.get("weight-loss/"+id+"/groups", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWeightLossMemberById = (id,params) => {
    return axiosClient.get("weight-loss/"+id+"/members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
export {
    getWeightLoss,
    getWeightLossDashboard,
    getWeightLossGroup,
    getWeightLossDetail,
    getWeightLossGroupDetail,
    getWeightLossGroupById,
    getWeightLossMemberById
}