import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getAreas,
    // getAreaDetail,
    // postArea,
} from './areaAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getAreasAsync = createAsyncThunk(
    'areas',
    async (params, thunkAPI) => {
        try {
            const response = await getAreas(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

// export const getAreaDetailAsync = createAsyncThunk(
//     'ncDetail',
//     async (id, thunkAPI) => {
//         try {
//             const response = await getAreaDetail(id);
//             // The value we return becomes the `fulfilled` action payload
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data)
//         }
//     }
// );

// export const postAreaAsync = createAsyncThunk(
//     'postArea',
//     async (data, thunkAPI) => {
//         try {
//             const response = await postArea(data);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data)
//         }
//     }
// );


export const areaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearAreaDetail: state => {
            state.detail = undefined;
        },
        clearAreas: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAreasAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAreasAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getAreasAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getAreas"
                    }
                }
            })
            // .addCase(getAreaDetailAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(getAreaDetailAsync.fulfilled, (state, action) => {
            //     state.detail = action.payload
            //     state.status = 'idle';
            //     state.error = undefined;
            // })
            // .addCase(getAreaDetailAsync.rejected, (state, action) => {
            //     state.status = 'idle';
            //     if (action?.error?.name !== "AbortError") {
            //         message.error("Lấy dữ liệu thất bại!")
            //         state.error = {
            //             payload: action.payload,
            //             title: "getAreaDetail"
            //         }
            //     }
            // })
            // .addCase(postAreaAsync.pending, (state) => {
            //     state.status = 'loading';
            // })
            // .addCase(postAreaAsync.fulfilled, (state, action) => {
            //     state.detail = action.payload
            //     state.status = 'done';
            //     state.error = undefined;
            // })
            // .addCase(postAreaAsync.rejected, (state, action) => {
            //     state.status = 'idle';
            //     if(action?.error?.name !== "AbortError"){
            //         message.error("Create Area failed")
            //         state.error = {
            //             payload: action.payload,
            //             title: "addArea"
            //         }
            //     }
            // })
    },
});

export const selectAreas = state => state.area.list
export const selectAreaDetail = state => state.area.detail
export const areasStatus = state => state.area.status
export const areasError = state => state.area.error

export const { clearAreaDetail, clearAreas, setIdleStatus } = areaSlice.actions;

export default areaSlice.reducer;

