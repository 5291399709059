import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getVersions = (params) => {
  return axiosClient.get("version-manage", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putVersion = ({ id, data }) => {
  return axiosClient.patch("version-manage/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const activeVersion = ({ id }) => {
  return axiosClient.patch(
    "version-manage/" + id + "/activate",
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const exportVersion = (params, options) => {
  return axiosClient.get("version-manage/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getVersions, exportVersion, putVersion, activeVersion };
