// BillSystemStatus 
const PENDING = {
    id: 1,
    key: '1',
    content: 'Pending',
    text: 'Chưa giải quyết'
} 
const GETSUCCESSFULL = {
    id: 2,
    key: '2',
    content: 'GetSuccessful',
    text: 'Thành công'
}
const GETFAILED = {
    id: 1,
    key: '1',
    content: 'GetFailed',
    text: 'Thất bại'
}
const COMPLETED = {
    id: 1,
    key: '1',
    content: 'Completed',
    text: 'Đã hoàn thành'
}


 const bill_system_status = [
    PENDING,
    GETSUCCESSFULL,
    GETFAILED,
    COMPLETED
 ]

// Bill status 

 const PACKING = {
    id: 1,
    key: '1',
    content: 'Packing',
    text: 'Đang đóng gói',
    color: 'blue'
}
 const INTRANSIT = {
    id: 2,
    key: '2',
    content: 'InTransit',
    text: 'Đang vận chuyển',
    color: 'purple'
}
 const DELIVER = {
    id: 3,
    key: '3',
    content: 'Deliver',
    text: 'Đang giao hàng',
    color: 'orange'
}
 const RETURNING = {
    id: 4,
    key: '4',
    content: 'Returning',
    text: 'Đang trả hàng',
    color: 'magenta'
}
 const REFUNDED = {
    id: 5,
    key: '5',
    content: 'Refunded',
    text: 'Đã hoàn lại',
    color: 'volcano'
}
 const SUCCESSFUL = {
    id: 6,
    key: '6',
    content: 'Successful',
    text: 'Đã giao hàng',
    color: 'green'
}

 const CANCELED = {
    id: 7,
    key: '7',
    content: 'Canceled',
    text: 'Đã hủy',
    color: 'red'
}

 const bill_status = [
    PACKING,
    RETURNING,
    INTRANSIT,
    DELIVER,
    REFUNDED,
    SUCCESSFUL,
    CANCELED
] 

const NORTH = {
    id: 1,
    key: '1',
    text: 'Miền Bắc',
    title: 'North'
}
const CENTRAL = {
    id: 2,
    key: '2',
    text: 'Miền Trung',
    title: 'Central'
}
const SOUTH = {
    id: 3,
    key: '3',
    text: 'Miền Nam',
    title: 'South'
}
const NE = {
    id: 4,
    key: '4',
    text: 'PUC Nghệ An',
    title: 'PUC Nghe An'
}
const UH = {
    id: 5,
    key: '5',
    text: 'PUC Phú Thọ',
    title: 'PUC Phu Tho'
}
const OV = {
    id: 6,
    key: '6',
    text: 'PUC Bình Dương',
    title: 'PUC Binh Duong'
}
const HA = {
    id: 7,
    key: '7',
    text: 'PUC Hải Phòng',
    title: 'PUC Hai Phong'
}
const list_region = [
    NORTH,
    CENTRAL,
    SOUTH,
    NE,
    UH,
    OV,
    HA
]
const listFilterStatus = [
    PACKING,
    INTRANSIT,
    DELIVER, 
    RETURNING,
    SUCCESSFUL,
    REFUNDED,
    CANCELED,
]

//So lan cho phep giao hang khong thanh cong
//Khi thong bao qua 5 lan thi disable nut thong bao
const numberOfFailedDeliveries = 5;

//Carriers
const VIETNAM_POST = {
    text: "Vietnam Post",
    name: "VNPost",
    id: 2,
    key: '2',
  }
const NHATTIN = {
    text: "Nhat Tin Logistics",
    name: "Nhat Tin",
    id: 1,
    key: '1',
}

const list_carrier = [
    VIETNAM_POST,
    NHATTIN
]
export const status_file_import = {
    PENDING: {
        id: 1,
        key: '1',
        name: 'Pending',
        text: 'Chờ xử lý',
        color: 'blue'
    },
    PROCESS: {
        id: 2,
        key: '2',
        name: 'Process',
        text: 'Đang xử lý',
        color: 'orange'
    },
    FAILED: {
        id: 3,
        key: '3',
        name: 'Failed',
        text: 'Thất bại',
        color: 'red'
    },
    CANCEL: {
        id: 4,
        key: '4',
        name: 'Cancel',
        text: 'Hủy bỏ',
        color: 'magenta'
    },
    SUCCESSFUL: {
        id: 5,
        key: '5',
        name: 'Successful',
        text: 'Thành công',
        color: 'green'
    },
}
const listStatusFileImport = Object.values(status_file_import);
export const findBillStatus = (id) => {
    return bill_status.find(s => s.id * 1 === id * 1); 
}
export const findRegion = (id) => {
    return list_region.find(s => s.id * 1 === id * 1); 
}
export const findStatusFileImport = (id, field) =>{
    const result = listStatusFileImport.find(s => s.id * 1 === id * 1); 
    if(field && result?.[field]) return result[field]
    return result
}
const textDefault = 'Đơn hàng của Quý Thành Viên đang được vận chuyển'
export {
    list_region,
    bill_status,
    listFilterStatus,
    bill_system_status,
    PACKING,
    INTRANSIT,
    DELIVER,
    RETURNING,
    REFUNDED,
    SUCCESSFUL,
    CANCELED,
    VIETNAM_POST,
    NHATTIN,
    list_carrier,
    numberOfFailedDeliveries,
    listStatusFileImport,
    textDefault
}