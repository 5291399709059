import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getMasterImportList,
    getMasterImportListTrackandTrace,
    postMasterImport
} from './masterImportAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    listOrder: undefined,
    status: 'idle',
    error: undefined,
};

export const getMasterImportAsync = createAsyncThunk(
    'getMasterImport',
    async (params, thunkAPI) => {
        try {
            const response = await getMasterImportList(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getMasterImportTrackandTraceAsync = createAsyncThunk(
    'getMasterImportTrackandTrace',
    async (params, thunkAPI) => {
        try {
            const response = await getMasterImportListTrackandTrace(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postMasterImportAsync = createAsyncThunk(
    'postMasterImport',
    async (data, thunkAPI) => {
        try {
            const response = await postMasterImport(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);


export const masterImportSlice = createSlice({
    name: 'masterImport',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearMasterImports: state => {
            state.list = undefined;
        },
        clearMasterImportsTrackandTrace: state => {
            state.listOrder = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getMasterImportAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMasterImportAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getMasterImportAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getMasterImport"
                    }
                }
            })
            .addCase(postMasterImportAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postMasterImportAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postMasterImportAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Import dữ liệu thất bại")
                    state.error = {
                        payload: action.payload,
                        title: "addMasterImport"
                    }
                }
            })
            .addCase(getMasterImportTrackandTraceAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getMasterImportTrackandTraceAsync.fulfilled, (state, action) => {
                state.listOrder = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(getMasterImportTrackandTraceAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Import dữ liệu thất bại")
                    state.error = {
                        payload: action.payload,
                        title: "addMasterImportTrackandTrace"
                    }
                }
            })
    },
});

export const selectMasterImport = state => state.masterImport.list
export const selectMasterImportTrackandTrace = state => state.masterImport.listOrder
export const masterImportsStatus = state => state.masterImport.status
export const masterImportsError = state => state.masterImport.error

export const { clearAreas, setIdleStatus, clearMasterImportsTrackandTrace } = masterImportSlice.actions;

export default masterImportSlice.reducer;

