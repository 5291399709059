import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getAreas = (params) => {
    return axiosClient.get("categories/area", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const getNCDetail = (id) => {
//     return axiosClient.get("nutrition-clubs/" + id, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

// const postNC = (data) => {
//     return axiosClient.post("nutrition-clubs", data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

// const putNC = (id, data) => {
//     return axiosClient.patch("nutrition-clubs/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getAreas,
    // getNCDetail,
    // postNC,
    // putNC
}