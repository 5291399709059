import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { message } from "antd";
import { numberOfFailedDeliveries } from "common/TrackAndTrace";
import { getTrackandTrace, getTrackandTraceDetail, getTrackandTraceLog, getTrackandTraceNote, postTrackandTraceNote, putTrackandTraceDeliveries, putTrackandTraceExpectedDate } from "./trackandtraceAPI";

const initialState = {
    list: undefined,
    detail: undefined,
    note: undefined,
    log: undefined,
    status: 'idle',
    error: undefined,
};

export const getTrackandTraceAsync = createAsyncThunk(
    'trackandtraces',
    async (params, thunkAPI) => {
        try {
            const response = await getTrackandTrace(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getTrackandTraceDetailAsync = createAsyncThunk(
    'trackandtraceDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getTrackandTraceDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getTrackandTraceNoteAsync = createAsyncThunk(
    'trackandtraceNote',
    async (id, thunkAPI) => {
        try {
            const response = await getTrackandTraceNote(id);
            // The value we return becomes the `fulfilled` action payload  
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);  
export const getTrackandTraceLogAsync = createAsyncThunk(
    'trackandtraceLog',
    async (id, thunkAPI) => {
        try {
            const response = await getTrackandTraceLog(id);
            // The value we return becomes the `fulfilled` action payload  
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);  
export const putTrackandTraceExpectedDateAsync = createAsyncThunk(
    'trackandtracePutExpectedDate',
    async ({id,data,user}, thunkAPI) => { 
        try {  
            const response = await putTrackandTraceExpectedDate(id,data);
            // The value we return becomes the `fulfilled` action payload  
            return {data:response.data,user};
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);  
export const putTrackandTraceDeliveriesAsync = createAsyncThunk(
    'trackandtracePutDeliveries',
    async (id, thunkAPI) => { 
        try {  
            const response = await putTrackandTraceDeliveries(id);
            // The value we return becomes the `fulfilled` action payload  
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
); 
export const postTrackandTraceNoteAsync = createAsyncThunk(
    'trackandtracePostNote',
    async ({id,data}, thunkAPI) => { 
        try {  
            const response = await postTrackandTraceNote(id,data);
            // The value we return becomes the `fulfilled` action payload  
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);  

export const trackandtraceSlice = createSlice({
    name: 'trackandtrace',
    initialState,
    reducers:{
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearTrackandTraceDetail: state => {
            state.detail = undefined;
        }, 
        clearTrackandTraceNote: state => {
            state.note = undefined;
        },
        clearTrackandTraceLog: state => {
            state.log = undefined;
        },
        clearTrackandTrace: state => {
            state.list = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getTrackandTraceAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getTrackandTraceAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(getTrackandTraceAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getTrackandTraces"
                }
            }
        })
        .addCase(getTrackandTraceDetailAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getTrackandTraceDetailAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(getTrackandTraceDetailAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getTrackandTracesDetail"
                }
            }
        })
        .addCase(getTrackandTraceNoteAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getTrackandTraceNoteAsync.fulfilled, (state, action) => {
            state.note = action.payload
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(getTrackandTraceNoteAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getTrackandTracesNote"
                }
            }
        })
        .addCase(putTrackandTraceExpectedDateAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(putTrackandTraceExpectedDateAsync.fulfilled, (state, action) => {
            state.log.result.data.unshift({
                createdAt: action?.payload?.data?.data?.updatedAt,
                oldValues:  current(state)?.detail?.data?.expectedDateInHouse && JSON.stringify({ExpectedDateInHouse: current(state)?.detail?.data?.expectedDateInHouse}),
                newValues: action?.payload?.data?.data?.expectedDateInHouse && JSON.stringify({ExpectedDateInHouse: action?.payload?.data?.data?.expectedDateInHouse}),
                type: 'Chỉnh sửa thời gian giao hàng dự kiên',
                creator: {name: action?.payload?.user?.info?.fullName}
            })
            state.detail = action.payload.data; 
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(putTrackandTraceExpectedDateAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Cập nhật dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "putTrackandTracesExpectedDate"
                }
            }
        })
        
        .addCase(postTrackandTraceNoteAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(postTrackandTraceNoteAsync.fulfilled, (state, action) => {
            state.note.data.unshift(action.payload.data);
            state.note.totalCount += 1;
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(postTrackandTraceNoteAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Cập nhật dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "postTrackandTracesNote"
                }
            }
        })
        .addCase(getTrackandTraceLogAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getTrackandTraceLogAsync.fulfilled, (state, action) => {
            state.log = action.payload; 
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(getTrackandTraceLogAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") {
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getTrackandTraceslog"
                }
            }
        })
        .addCase(putTrackandTraceDeliveriesAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(putTrackandTraceDeliveriesAsync.fulfilled, (state, action) => {
            message.success(`Đã trì hoãn thời gian giao hàng. Bạn còn ${numberOfFailedDeliveries - (state.detail.data.notifyWarnings+1)} lần gia hạn nữa!`,2) 
            state.detail.data.notifyWarnings += 1;
            state.status = 'idle';
            state.error = undefined;
        })
        .addCase(putTrackandTraceDeliveriesAsync.rejected, (state, action) => {
            state.status = 'idle';
            if (action?.error?.name !== "AbortError") { 
                message.error("Bạn đã trì hoãn thời gian giao hàng đủ 5 lần. Bạn không thể trì hoãn thêm.")
                state.error = {
                    payload: action.payload,
                    title: "putTrackandTracesDeliveries"
                }
            }
        })
    },
});

export const selectTrackandTraces = state => state.trackandtraces.list
export const selectTrackandTraceDetail = state => state.trackandtraces.detail
export const selectTrackandTraceNote = state => state.trackandtraces.note
export const selectTrackandTraceLog = state => state.trackandtraces.log
export const trackandtracesStatus = state => state.trackandtraces.status
export const trackandtracesError = state => state.trackandtraces.error

export const { setIdleStatus, clearTrackandTraceNote, clearTrackandTraceDetail, clearTrackandTrace, clearTrackandTraceLog } =  trackandtraceSlice.actions;

export default trackandtraceSlice.reducer;

