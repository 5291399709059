import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getNCs,
    getNCDetail,
    postNC,
    getRequestNCs,
} from './ncAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
    request: undefined
};

export const getNCsAsync = createAsyncThunk(
    'ncs',
    async (params, thunkAPI) => {
        try {
            const response = await getNCs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getNCDetailAsync = createAsyncThunk(
    'ncDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getNCDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const postNCAsync = createAsyncThunk(
    'postNC',
    async (data, thunkAPI) => {
        try {
            const response = await postNC(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const getRequestNCsAsync = createAsyncThunk(
    'getRequestNcs',
    async (params, thunkAPI) => {
        try {
            const response = await getRequestNCs(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);


export const ncSlice = createSlice({
    name: 'nc',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearNCDetail: state => {
            state.detail = undefined;
        },
        clearNCs: state => {
            state.list = undefined;
        },
        clearRequestNCs: state => {
            state.request = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getNCsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCsAsync.rejected, (state, action) => {
                state.status = 'idle';
                
                if (action?.error?.name !== "AbortError") {
                    //message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCs"
                    }
                }
            })
            .addCase(getNCDetailAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNCDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getNCDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getNCDetail"
                    }
                }
            })
            .addCase(postNCAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postNCAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(postNCAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thêm mới NDD thất bại")
                    state.error = {
                        payload: action.payload,
                        title: "addNC"
                    }
                }
            })
            .addCase(getRequestNCsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRequestNCsAsync.fulfilled, (state, action) => {
                state.request = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getRequestNCsAsync.rejected, (state, action) => {
                state.status = 'idle';
                
                if (action?.error?.name !== "AbortError") {
                    //message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getRequestNCs"
                    }
                }
            })
    },
});

export const selectNCs = state => state.nc.list
export const selectNCDetail = state => state.nc.detail
export const selectRequestNCs = state => state.nc.request
export const ncsStatus = state => state.nc.status
export const ncsError = state => state.nc.error

export const { clearNCDetail, clearNCs, setIdleStatus, clearRequestNCs } = ncSlice.actions;

export default ncSlice.reducer;

