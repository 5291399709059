import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};
const getManageGift = (params) => {
  return axiosClient.get("gift", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getManageGiftById = (id) => {
  return axiosClient.get("gift/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putManageGift = (id,data) => {
  return axiosClient.patch("gift/"+id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postManageGift = (data) => {
  return axiosClient.post("gift", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getManageGift, postManageGift, getManageGiftById, putManageGift };
