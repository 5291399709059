import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { login } from "./authAPI";

const initialState = {
  token:
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined,
  info: (localStorage?.auth && JSON.parse(localStorage.auth)) || undefined,
  status: "idle",
  errorCode: undefined,
  tokenFirstLogin: undefined,
};

export const authAsync = createAsyncThunk(
  "login/post",
  async (data, { rejectWithValue }) => {
    try {
      const response = await login(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = undefined;
      state.info = undefined;
      state.errorCode = undefined;
      state.tokenFirstLogin = undefined;
      localStorage.removeItem("auth");
      localStorage.removeItem("token");
      return state;
    },
    updateAuth: (state, action) => {
      state.info = action.payload;
      localStorage.setItem("auth", JSON.stringify(action.payload));
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(authAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.token = action.payload.result.accessToken;
        state.info = action.payload.result;
        state.errorCode = undefined;
      })
      .addCase(authAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.payload?.status === 409) {
          state.errorCode = action?.payload?.status;
          state.tokenFirstLogin = action?.payload.data.result.accessToken;
        } else {
          message.error(action?.payload?.data?.message); //"Bạn đã nhập sai tên tài khoản/mật khẩu")
        }
      });
  },
});

export const getInforUser = (state) => state.auth.info;
export const { logout, updateAuth } = authSlice.actions;

export default authSlice.reducer;
