import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getNCCs = (params) => {
    return axiosClient.get("members", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCCDetail = (id) => {
    return axiosClient.get("members/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postNCC = (data) => {
    return axiosClient.post("members", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getNCs = (params) => {
    return axiosClient.get("nutrition-clubs", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putNCC = (data) => {
    return axiosClient.patch("members/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getType = (params) => {
    return axiosClient.get("enums/nutrition-club-customer-levers", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportNCCs = (params, options) => {
    return axiosClient.get("members/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const exportPresenters = (params, options) => {
    return axiosClient.get("members/referral/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPresenterNCCs = (params) => {
    return axiosClient.get("members/referral", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getRelativeNCCs = (memberId, params) => {
    console.log('memberId', memberId)
    return axiosClient.get(`members/${memberId}/relative`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const deleteRelativeNCC = (relativeId) => {
    return axiosClient.delete(`members/relative/${relativeId}` , {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postRelativeNCC = (memberId, data) => {
    return axiosClient.post(`members/${memberId}/relative`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putRelativeNCC = (data) => {
    return axiosClient.patch("members/relative/" + data.relativeId, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNCCs,
    getNCCDetail,
    postNCC,
    getNCs,
    putNCC,
    getType,
    exportNCCs,
    getPresenterNCCs,
    exportPresenters,
    getRelativeNCCs,
    deleteRelativeNCC,
    postRelativeNCC,
    putRelativeNCC
}