import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getActivityLog } from './activeLogAPI';

const initialState = {
  activityLog: undefined,
  status: 'idle',
  error: undefined,
};

export const getActivityLogAsync = createAsyncThunk(
  'activityLog',
  async (params, thunkAPI) => {
    try {
      const response = await getActivityLog(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const activityLogSlice = createSlice({
  name: 'activityLog',
  initialState,
  reducers: {
    setIdleStatus: state => {
      state.status = "idle"
    },
    clearActivityLog: state => {
      state.activityLog = undefined;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getActivityLogAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getActivityLogAsync.fulfilled, (state, action) => {
        state.activityLog = action.payload
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getActivityLogAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!")
          state.error = {
            payload: action.payload,
            title: "activityLog"
          }
        }
      })
  },
});

export const selectActivityLog = state => state.activityLog.activityLog
export const activityLogStatus = state => state.activityLog.status
export const activityLogError = state => state.activityLog.error

export const { clearActivityLog, setIdleStatus } = activityLogSlice.actions;

export default activityLogSlice.reducer;

