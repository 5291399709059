import { message, notification, Button } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { cancelRequestLogin } from "./Auth/authAPI";
import { logout } from "./Auth/authAPI";

// const close = (actionKey) => {
//     if (actionKey === 'cancel') {
//         cancelRequestLogin().then(req => {
//             message.success("Hủy lệnh yêu cầu đăng nhập từ thiết bị khác thành công")
//         }).catch(error => {
//             message.error(error?.response?.data?.message)
//         })
//     }
//     else {
//         logout().then(req => {
//             localStorage.removeItem("auth")
//             localStorage.removeItem("token")
//             window.location.replace("/")
//         })
//     }
//     notification.close('noti')
// }

// const openNotification = (title) => {
//     const key = `noti`;
//     const btn = (
//         <>
//             <Button type="danger" className="mr-2" onClick={() => close('cancel')}>
//                 Hủy yêu cầu
//             </Button>
//             <Button type="primary" onClick={() => close('logout')}>
//                 Đăng xuất
//             </Button>
//         </>
//     );
//     notification.open({
//         message: title,
//         description: 'Hủy lệnh yêu cầu đăng nhập để tiếp tục công việc hoặc đăng xuất trên thiết bị hiện tại để đăng nhập từ thiết bị khác.',
//         btn,
//         key,
//         duration: 0,
//         onClose: close,
//         closeIcon: false
//     });
// };

const version = "v1/"
const instance = axios.create({
    baseURL: process.env.REACT_APP_API + version + "admin-panel/"
})

// instance.interceptors.request.use(function (config) {
//     return config;
// }, function (error) {
//     return Promise.reject(error)
// })

instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error?.response?.status === 401) {
        localStorage.removeItem("auth")
        localStorage.removeItem("token")
        window.location.replace("/")
    }
    // if (error?.response?.status === 406) {
    //     openNotification(error?.response?.data?.message)
    // }
    return Promise.reject(error)
})

export default instance