import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getCreator } from "./creatorAPI";

const initialState = {
  creators: undefined,
  status: "idle",
};

export const getCreatorAsync = createAsyncThunk(
  "getCreator",
  async (params, thunkAPI) => {
    try {
      const response = await getCreator(params);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const creatorSlice = createSlice({
  name: "creator",
  initialState,
  reducers: {
    clearCreator: (state) => {
      state.creator = undefined;
    },
    setIdleStatus: (state) => {
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCreatorAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCreatorAsync.fulfilled, (state, action) => {
        state.creators = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCreatorAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCreator",
          };
        }
      });
  },
});

export const selectCreator = (state) => state.creator.creators;
export const creatorsStatus = (state) => state.creator.status;

export const { clearCreator, setIdleStatus } = creatorSlice.actions;

export default creatorSlice.reducer;
