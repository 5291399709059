import axiosClient from "../axiosClient" 

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}
const getTrackandTrace  = (params) => {
    return axiosClient.get("trackandtraces", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getTrackandTraceDetail = (id) => {
    return axiosClient.get("trackandtraces/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getTrackandTraceNote = (id) => {
    return axiosClient.get("trackandtraces/" + id + "/note", {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getTrackandTraceLog = (id) => {
    return axiosClient.get("trackandtraces/" + id + "/log", {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getTrackandTraceImport = (params) => {
  return axiosClient.get("trackandtraces/order/import", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const exportTrackAndTrace = (params, options) => {
    return axiosClient.get("trackandtraces/export", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }, 
        responseType: 'blob'
    })
}
const putTrackandTraceExpectedDate = (id,data) => {
    return axiosClient.patch("trackandtraces/" + id + "/expected-date", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const putTrackandTraceUpdateNofity = (params, data) => {
  return axiosClient.patch("trackandtraces/multi-expected-date", data, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putTrackandTraceDeliveries = (id) => {
    return axiosClient.post("trackandtraces/" + id + "/notify",{}, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const putTrackandTraceImport = (data) => {
    return axiosClient.patch("trackandtraces/order/import", data,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const postTrackandTraceNote = (id,data) => {
    return axiosClient.post("trackandtraces/" + id + "/note", data,{
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
export {
    getTrackandTrace,
    getTrackandTraceDetail,
    getTrackandTraceNote,
    getTrackandTraceLog,
    getTrackandTraceImport,
    putTrackandTraceExpectedDate,
    putTrackandTraceDeliveries,
    putTrackandTraceUpdateNofity,
    postTrackandTraceNote,
    putTrackandTraceImport,
    exportTrackAndTrace,
}