import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getInfoDashboard = (params) => {
  return axiosClient.get("dashboard/information", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getHealthAmbassador = (params) => {
  return axiosClient.get("statistics/representative-health-ambassador", {
    //dai su suc khoe tieu bieu
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getNotiMembers = (params) => {
  return axiosClient.get("statistics/noticeable-members", {
    //thanh vien can chu y
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getRepresentativeMembers = (params) => {
  return axiosClient.get("statistics/representative-member", {
    //thanh vien noi bat trong thang
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getRepresentativeCustomer = (params) => {
  return axiosClient.get("statistics/representative-customer", {
    //Khachs hang noi bat trong thang
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getSoldPackages = (params) => {
  return axiosClient.get("statistics/sold-packages", {
    //Tong goi dinh duong
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportDashboard = (data) => {
  return axiosClient.post("report/retail-sales", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getChartDashboard = (params) => {
  return axiosClient.get("dashboard/chart", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getReportFile = (params) => {
  return axiosClient.get("dashboard/report-file", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getInfoDashboard,
  getHealthAmbassador,
  getNotiMembers,
  getRepresentativeMembers,
  getRepresentativeCustomer,
  getSoldPackages,
  exportDashboard,
  getChartDashboard,
  getReportFile
};
