import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getManageGift, getManageGiftById, putManageGift } from "./manageGiftAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
};

export const getManageGiftAsync = createAsyncThunk(
  "manageGifts",
  async (params, thunkAPI) => {
    try {
      const response = await getManageGift(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getManageGiftByIdAsync = createAsyncThunk(
  "manageGiftById",
  async (id, thunkAPI) => {
    try {
      const response = await getManageGiftById(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putManageGiftByIdAsync = createAsyncThunk(
  "manageGiftUpdate",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await putManageGift(id,data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const manageGiftSlice = createSlice({
  name: "manageGifts",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearManageGift: (state) => {
      state.list = undefined;
    },
    clearManageGiftDetail: (state) => (state.detail = undefined),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageGiftAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getManageGiftAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getManageGiftAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getManageGift",
          };
        }
      })
      .addCase(getManageGiftByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getManageGiftByIdAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getManageGiftByIdAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getManageGiftById",
          };
        }
      })
      .addCase(putManageGiftByIdAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(putManageGiftByIdAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.detail = action.payload;
        state.error = undefined;
      })
      .addCase(putManageGiftByIdAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getManageGiftById",
          };
        }
      });
  },
});

export const selectManageGift = (state) => state.manageGift.list;
export const selectManageGiftDetail = (state) => state.manageGift.detail;
export const managesGiftStatus = (state) => state.manageGift.status;

export const { setIdleStatus, clearManageGift, clearManageGiftDetail } =
  manageGiftSlice.actions;

export default manageGiftSlice.reducer;
