import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getProvinces = (params) => {
    return axiosClient.get("province", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getDistricts = (provinceId, params) => {
    return axiosClient.get(`province/${provinceId}/district`, {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getProvinces,
    getDistricts
}