import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getSimulations = (params) => {
  return axiosClient.get("simulator", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getSimulationDetail = (id) => {
  return axiosClient.get("simulator/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postSimulation = (data) => {
  return axiosClient.post("simulator", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putSimulation = ({id, data}) => {
  return axiosClient.patch("simulator/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const deleteSimulation = (id) => {
  return axiosClient.delete("simulator/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportSimulation = (params, options) => {
  return axiosClient.get("simulator/export", {
      params,
      ...options,
      headers: {
          Authorization: "Bearer " + getToken()
      }
  })
}

const getRevenue = (data) => {
  return axiosClient.post("simulator/revenue", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { 
  getSimulations, 
  getSimulationDetail, 
  postSimulation, 
  putSimulation,
  deleteSimulation,
  exportSimulation,
  getRevenue
 };
