import { options } from "numeral";
import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getCreator = (params) =>{
    return axiosClient.get("member-credentials/creator",{
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getCreator
}