import axiosClient from "../axiosClientNewme";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getMemberNewme = (params) => {
  return axiosClient.get("admin/new-me-member/get-all", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getMemberNewmeDetail = (id, params) => {
  return axiosClient.get("admin/new-me-member/" + id, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getMemberNewmeHealthDetail = (id) => {
  return axiosClient.get("admin/new-me-health-information/" + id+'/detail', {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getNewmeHistory = (id, params) => {
  return axiosClient.get("admin/new-me-health-information/" + id, {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportNewmeNCC = (params, options) => {
  return axiosClient.get("admin/exports/customers", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportNewmeNCO = (params, options) => {
  return axiosClient.get("admin/exports/operators", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const exportNewmeTab = (params, options) => {
  return axiosClient.get("admin/exports/tabs", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getMemberNewme,
  getMemberNewmeDetail,
  getNewmeHistory,
  exportNewmeNCC,
  exportNewmeNCO,
  exportNewmeTab,
  getMemberNewmeHealthDetail
};
