import axiosClient from "../axiosClient";
const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getPackages = (params) => {
  return axiosClient.get("package", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getPackageMember = (idMember) => {
  return axiosClient.get("ncc-packages/members/" + idMember, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getPackageDetail = (id) => {
  return axiosClient.get("package/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getPackagesByUnit = (params) => {
  return axiosClient.get("packages-detail", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postPackage = (data) => {
  return axiosClient.post("package", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPackage = (data) => {
  return axiosClient.patch("package/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPackageImage = (id, data) => {
  return axiosClient.patch("package/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPackageStatus = (id, data) => {
  return axiosClient.put("packages-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const removeProductPackage = (packageId, productGroupId) => {
  return axiosClient.delete(
    `/package/${packageId}/product-group/${productGroupId}`,
    {
      headers: { Authorization: "Bearer " + getToken() },
    }
  );
};

const addProductPackage = (packageId, payload) => {
  return axiosClient.post(`package/${packageId}/products`, payload, {
    headers: { Authorization: "Bearer " + getToken() },
  });
};

const exportPackages = (params, options) => {
  return axiosClient.get("package/export", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const deleteProductPackageImage = (id, fileUploadId) => {
  return axiosClient.delete(`package/${id}/` + `picture/${fileUploadId}`, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getPricePointPackages = (params) => {
  return axiosClient.post("package/price-point", params, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getTop5Packages = (params) => {
  return axiosClient.get("dashboard/top-package", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getTop5Products = (params) => {
  return axiosClient.get("dashboard/top-product", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getPackages,
  getPackageDetail,
  postPackage,
  putPackage,
  putPackageStatus,
  putPackageImage,
  getPackagesByUnit,
  getPackageMember,
  removeProductPackage,
  addProductPackage,
  exportPackages,
  deleteProductPackageImage,
  getPricePointPackages,
  getTop5Packages,
  getTop5Products,
};
