import axios from "axios";
import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API + "v2/admin-panel/",
});

const getMasterImportList = (params) => {
  return axiosClient.get("admin-import", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getMasterImportListTrackandTrace = (params) => {
  return axiosClient.get("trackandtraces/order/import", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getDownloadTemplate = (params) => {
  return axiosClient.get("admin-import/template", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
}; 

const postMasterImport = (url, data) => {
  if (url === "ncc") {
    return instance.post("admin-import/" + url, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  }else if (url === "trackandtraces") {
    return axiosClient.post(url+"/order/import" , data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  } else {
    return axiosClient.post("admin-import/" + url, data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  }
};
const postMasterImportTrackAndTrace = (url, data) => {
  if (url === "trackandtraces") {
    return axiosClient.post(url + "/order/import", data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  }
  if(url === 'gift'){
    return axiosClient.post("trackandtraces/order/import-gift", data, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
  }
  return axiosClient.post("trackandtraces/import/" + url, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getMasterImportList, postMasterImport, getDownloadTemplate,getMasterImportListTrackandTrace, postMasterImportTrackAndTrace };
