import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getActivityLog = (params) => {
    return axiosClient.get(`${params.url}/${params.id}/log`, {
        params: params.values,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getActivityLog,
}