import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) || undefined;
}

const getUsers = (params) => {
    return axiosClient.get("member-credentials", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getUserDetail = (id) => {
    return axiosClient.get("member-credentials/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postUser = (data) => {
    return axiosClient.post("member-credentials", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUser = (data) => {
    return axiosClient.patch("member-credentials/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUserStatus = (memberCredentialId, data) => {
    return axiosClient.patch(`/member-credentials/${memberCredentialId}/status`, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUserPassword = (data) => {
    return axiosClient.post("authentication/change-password", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPasswordForUser = (id, data) => {
    // return axiosClient.put("users-password/" + id, data, {
    //     headers: {
    //         Authorization: "Bearer " + getToken()
    //     }
    // })
}

const resetPasswordForUser = (id) => {
    return axiosClient.patch(`member-credentials/reset-password?memberCredentialId=${id}`,null, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
export {
    getUsers,
    getUserDetail,
    postUser,
    putUser,
    putUserStatus,
    putUserPassword,
    putPasswordForUser,
    resetPasswordForUser,
}